<template>
  <div>
    <p class="mb-6">Ajustes arqueo caja</p>

    <v-card>
      <v-card-subtitle>Filtros</v-card-subtitle>
      <v-card-text>
        <v-row>
          <v-col cols="6">
            <dos-fechas
              :fechas="[filtros.fecha.desde, filtros.fecha.hasta]"
              @getFechas="getFechas"
              :icon="false"
            ></dos-fechas>
          </v-col>

          <v-col cols="6" class="text-right">
            <v-btn :loading="cargando" :disabled="cargando || !validar()" color="primary" @click="buscarDatos()">
              Buscar
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-subtitle>Resultado</v-card-subtitle>
      <v-card-text>
        <tabla-reporte
          @GetVer="getVer"
          :acciones="['VER']"
          :nombreReporte="nombreReporte"
          :cabeceraIn="cabecera"
          :datosIn="datos"
          :exportar="['EXCEL', 'PDF']"
        ></tabla-reporte>
      </v-card-text>
    </v-card>
    <ModalViewAjuste ref="ModalViewAjusteRef" :VerButton="false"></ModalViewAjuste>
  </div>
</template>
  
<script>
// eslint-disable-next-line object-curly-newline
import { mdiPoll, mdiLabelVariantOutline, mdiCurrencyUsd, mdiHelpCircleOutline } from '@mdi/js'
import TablaReporte from '@/components/TablaReporte.vue'
import { ref } from '@vue/composition-api'
import FormaPagoServices from '@/api/servicios/FormaPagoServices'
import store from '@/store'
import AreaSelect from '../../../inventario/archivo/area/componentes/AreaSelect.vue'
import DosFechas from '@/components/DosFechas.vue'
import FuncionesGenerales from '@/funciones/funciones'
import SelectMotivoAjuste from '../../../inventario/archivo/MotivoAjuste/componentes/Select.vue'
import moment from 'moment'
import ModalViewAjuste from '../../../inventario/ajuste/ajuste/componentes/ModalViewAjuste.vue'
// demos

export default {
  components: { TablaReporte, DosFechas, AreaSelect, SelectMotivoAjuste, ModalViewAjuste },
  setup() {
    const filtros = ref({
      fecha: {
        desde: moment().format('YYYY-MM-DD'),
        hasta: moment().format('YYYY-MM-DD'),
      },
      idSucursal: store.state.sucursalSelect.id,
    })

    const nombreReporte = ref(
      `Reporte ajuste detalle por producto ${filtros.value.fecha.desde} ${filtros.value.fecha.hasta}`,
    )

    const cabecera = ref([
      /*
      { text: 'ID', value: 'ajuste.id', },
      { text: 'Fecha Ajuste', value: 'ajuste.fechaAjuste', width: '130px', visible: true , format: { date: true } },
      { text: 'Documento', value: 'ajuste.numeroDocumento', width: '160px', visible: true },
      { text: 'Motivo', value: 'motivoAjuste.descripcion', width: '130px', visible: true },
      { text: 'Barra', value: 'productoSucursal.producto.barra', format: { small: true } , width: '130px', visible: true },
      { text: 'Producto', value: 'productoSucursal.producto.nombre', width: '130px', visible: true },
      { text: 'Lote', value: 'lote.numeroLote', width: '130px', visible: true },
      { text: 'Area', value: 'area.nombre', width: '130px', visible: true },
      { text: 'Cant ajus', value: 'totalUnidades', width: '130px', visible: true, align:"right"  },
      { text: 'Estatus', value: 'ajuste.estatus.descripcion', width: '130px', visible: true,  size: 'small', chip: true, color: ['primary', 'error', 'info'], valores: ['Finalizado', 'Anulado', 'borrador'] } 
  */
    ])
    const datos = ref([])
    const cargando = ref(false)
    const ModalViewAjusteRef = ref(null)

    const validar = () => {
      let validado = true

      return validado
    }

    const buscarDatos = () => {
      cargando.value = true
      FormaPagoServices.ArqueoCajaConsultar({
        idSucursal: filtros.value.idSucursal,
        fechaDesde: FuncionesGenerales.formatoFecha(filtros.value.fecha.desde, 5),
        fechaHasta: FuncionesGenerales.formatoFecha(filtros.value.fecha.hasta, 5),
      })
        .then(response => {
          if ((response.data.estatus = true)) {
            console.log('buscarDatos', JSON.parse(response.data.datos))
            datos.value = JSON.parse(response.data.datos).Registros
            const columas = JSON.parse(response.data.datos).ColumnaNombre.split(',')
            cabecera.value = [
              { text: 'Fecha', value: 'Fecha' , visible: true },
              { text: 'Sucursal', value: 'Sucursal.Nombre' , visible: true },
              { text: 'Caja', value: 'Caja.Descripcion', visible: true },
            ]

            columas.forEach(element => {
              cabecera.value.push({ text: FuncionesGenerales.camelCase( element.replace("_"," ")), value: element , visible: true, align:"right"   })
            });

            cabecera.value.push({ text: "Total Real "+ FuncionesGenerales.monedaPrincipal().simbolo , value: "TotalMontoPagoReal" , visible: true ,  align:"right"  })
            //{ text: 'ID', value: 'ajuste.id', },
          } else {
            store.commit('setAlert', {
              message: response.data.mensaje,
              type: 'error',
            })
          }
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          cargando.value = false
        })
    }

    const getVer = dato => {
      console.log(dato)
      ModalViewAjusteRef.value.abrir(dato.idAjuste)
    }

    const getFechas = datos => {
      console.log(datos)
      filtros.value.fecha.desde = datos.desde
      filtros.value.fecha.hasta = datos.hasta
    }

    return {
      cabecera,
      datos,
      validar,
      buscarDatos,
      cargando,
      nombreReporte,
      getVer,
      filtros,
      FuncionesGenerales,
      getFechas,
      ModalViewAjusteRef,
    }
  },
}
</script>
  